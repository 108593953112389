export const ENDPOINTS = {
  API: {
    SEND_EMAIL: '/api/send-email',
  },
  ASSESSMENTS: {
    BY_ID: (assessmentId: string): string => `/assessments/${assessmentId}`,
    DEVELOPMENT_DESCRIPTIONS: '/assessments/development-descriptions',
    ROOT: '/assessments',
    SCORE_DESCRIPTIONS: '/assessments/score-descriptions',
    QUESTIONS_BY_ASSESSMENT: (assessmentId: string): string =>
      `/assessments/${assessmentId}/questions`,
    QUESTIONS: '/assessments/questions',
  },
  CAMPAIGN: {
    ADD_CANDIDATE: (campaignId: string): string =>
      `/campaigns/${campaignId}/add-candidates`,
    ACCEPT: (campaignId: string): string => `/campaigns/${campaignId}/accept`,
    BY_ID: (campaignId: string): string => `/campaigns/${campaignId}`,
    DRAFT: '/campaigns/draft',
    REJECT: (campaignId: string): string => `/campaigns/${campaignId}/reject`,
    ROOT: '/campaigns',
    SEARCH: '/campaigns/search',
    SEND_FEEDBACK: (campaignId: string): string =>
      `/campaigns/${campaignId}/send-results`,
  },
  CANDIDATE: {
    ASSESSMENTS: (candidateId: string): string =>
      `/candidates/${candidateId}/assessments`,
    BY_CAMPAIGN: (campaignId: string): string =>
      `/candidates?campaignIds=${campaignId}`,
    CHANGE_DEADLINE: (candidateId: string): string =>
      `/candidates/${candidateId}/change-deadline`,
    CHANGE_DEADLINES: '/candidates/change-deadlines',
    INFO: (candidateId: string): string => `/candidates/${candidateId}`,
    RESEND_ASSESSMENTS: (candidateId: string): string =>
      `/candidates/${candidateId}/resend-assessments`,
    RESEND_LINK: '/candidates/resend-link',
    ROOT: '/candidates',
    TAGS: {
      SINGLE_CANDIDATE: (candidateId: string): string =>
        `/candidates/${candidateId}/tags`,
      MULTIPLE_CANDIDATES: `/candidates/tags`,
    },
    TEST_URL: (candidateId: string): string =>
      `/candidates/${candidateId}/test-url`,
    UPDATE_DETAILS: (candidateId: string): string =>
      `/candidates/${candidateId}/details`,
  },
  COMPETENCIES: {
    ROOT: '/competencies',
    INDIVIDUAL_TIPS: '/competencies/individual-tips',
  },
  EMPLOYEES: {
    BY_ID: (employeeId: string): string => `/employees/${employeeId}`,
    COMPETENCIES: (employeeId: string): string =>
      `/employees/${employeeId}/competencies`,
    SURVEYS: (employeeId: string): string => `/employees/${employeeId}/surveys`,
    CHANGE_DEADLINES: '/employees/change-deadlines',
    TEST_URL: (employeeId: string): string =>
      `/employees/${employeeId}/test-url`,
  },
  EMPLOYEE_PROFILES: {
    BY_ID: (profileId: string) => `/employee-profiles/${profileId}`,
  },
  EMPLOYEE: {
    RESEND_COMPETENCIES: (employeeId: string): string =>
      `/employees/${employeeId}/resend-competencies`,
    RESEND_LINK: 'employees/resend-link',
    UPDATE_DETAILS: (projectId: string, employeeId: string): string =>
      `/projects/${projectId}/employees/${employeeId}`,
    DELETE: (projectId: string): string => `/projects/${projectId}/employees`,
    ADD: (projectId: string): string => `/projects/${projectId}/employees`,
  },
  EXPERIMENTS: {
    ME: '/experiments/me',
  },
  OCCUPATIONS: {
    ROOT: '/occupations',
    SUGGEST: '/occupations/suggest',
    BY_ID: (occupationId: string): string => `/occupations/${occupationId}`,
    MOST_USED: '/occupations/most-used',
    RECOMMENDED_ASSESSMENTS: (occupationId: string): string =>
      `/occupations/${occupationId}/recommended-assessments`,
  },
  NETLIFY_FUNCTIONS: {
    EMPLOYEE_REPORT: '/.netlify/functions/reports',
  },
  ORGANIZATION: {
    AVAILABLE_ASSESSMENTS: (occupationId: string) =>
      `/organisations/available-assessments?occupationId=${occupationId}`,
    AVAILABLE_PRODUCTS: (organisationId: string) =>
      `/organisations/${organisationId}/available-products`,
    CHECKOUT: (organisationId: string) =>
      `/organisations/${organisationId}/checkout`,
    CREDITS: '/credit-transactions/balance',
    CREDITS_REFILL: (organisationId: string): string =>
      `/organisations/${organisationId}/upgrade`,
    CUSTOMER_PORTAL: (organisationId: string): string =>
      `/organisations/${organisationId}/customer-portal`,
    END_TRIAL: (organisationId: string) =>
      `/organisations/${organisationId}/trial-end`,
    REQUEST_PRODUCT_ACCESS: (organisationId: string) =>
      `/organisations/${organisationId}/request-product-access`,
    ROOT: (organisationId: string): string =>
      `/organisations/${organisationId}`,
    TRIAL_CHECKOUT: (organisationId: string) =>
      `/organisations/${organisationId}/trial-checkout`,
    METRICS: (organisationId: string) =>
      `/organisations/${organisationId}/metrics`,
    COLLEAGUES: '/organisations/colleagues',
  },
  PROJECTS: {
    BY_ID: (projectId: string): string => `/projects/${projectId}`,
    ROOT: '/projects',
    JOB_FUNCTIONS: 'projects/job-functions',
    SEARCH: '/projects/search',
    RESULTS_OVERALL: '/results/overall',
    RESULTS_SEARCH: '/results/search',
    RESULTS_COMPETENCIES: '/results/competencies',
    RESULTS_OVERALL_RISKS: '/results/overall/risks',
    RESULTS_OVERALL_TEAMS: '/results/overall/teams',
    RESULTS_OVERALL_INSIGHTS: '/results/overall/insights',
    RESULTS_SURVEYS: '/results/surveys',
    TEAMS: (projectId: string) => `/projects/${projectId}/teams`,
    TEAM_BY_ID: (projectId: string, teamId: string) =>
      `/projects/${projectId}/teams/${teamId}`,
    RESULTS_AON_CALLBACK: '/results/aon-callback',
    RESULTS_AVA_CALLBACK: '/results/ava-callback',
    DRAFT: '/projects/draft',
    HANDLE_SURVEY: (projectId: string) => `/projects/${projectId}/survey`,
  },
  PROJECTS_RESULTS: {
    BY_EMPLOYEE: (employeeId: string) => `/results/${employeeId}`,
    SURVEYS_RISKS_BY_TEAM: '/results/surveys/teams',
  },
  PROFILE: (profileId: string): string => `/profiles/${profileId}`,
  PUBLIC_CANDIDATE: '/public-candidate',
  SCORES: {
    AON_CALLBACK: '/scores/aon-callback',
    AVA_CALLBACK: '/scores/ava-callback',
    BY_CAMPAIGN_ID: (campaignId: string): string =>
      `/scores/search?campaignIds=${campaignId}`,
    BY_CANDIDATE_ID: (candidateId: string): string => `/scores/${candidateId}`,
    INSIGHTS: (candidateId: string): string => `/scores/${candidateId}/insights`,
    METRICS: (campaignId: string): string =>
      `/scores/metrics?campaignId=${campaignId}`,
  },
  SIGNUP: {
    ROOT: '/sign-up',
  },
  USER: {
    LOG_IN: '/oauth/token',
    ME: '/users/me',
    REQUEST_RESET_PASSWORD: '/users/request-reset-password',
    RESET_PASSWORD: '/users/reset-password',
    SIGN_UP: '/users',
    VALIDATE_RESET_TOKEN: '/users/validate-reset-password-token',
    ONBOARDING: (userId: string) => `/users/${userId}/onboarding`,
    JOB_FUNCTIONS: '/users/job-functions',
  },
  SKILL_ASSESSMENTS: {
    ROOT: '/skill-assessments',
    BY_ID: (assessmentId: string): string =>
      `/skill-assessments/${assessmentId}`,
  },
  SURVEYS: {
    ROOT: '/surveys',
  },
  LANGUAGES: {
    ROOT: '/languages',
  },
  SKILLS: {
    ROOT: '/skills',
  },
  NATIONALITIES: {
    ROOT: '/nationalities',
  },
  POLLINATION: {
    BY_ID: (pollinationId: string) => `/candidate-pollination/${pollinationId}`,
    DOWNLOAD_CV_SIGNED_URL: (candidatePollinationId: string) =>
      `/candidate-pollination/${candidatePollinationId}/cv-signed-url`,
    CV_SIGNED_URL: '/candidate-pollination/cv-signed-url',
    HIRE: '/candidate-pollination-hired',
    ROOT: '/candidate-pollination',
  },
  SHORTLISTS: {
    ROOT: '/shortlists',
    SEARCH: '/shortlists/search',
    BY_ID: (shortlistId: string) => `/shortlists/${shortlistId}`,
    CANDIDATES: (shortlistId: string) =>
      `/shortlists/${shortlistId}/candidates`,
  },
  SHORTLISTS_CANDIDATES: {
    SEARCH: '/shortlist-candidate/search',
  },
  GRAPHQL: '/graphql',
  SHAREABLE_TOKENS: {
    ROOT: '/shareable-tokens',
  },
}
